<template>
  <div class="event-settings" v-if="!loading">
    <p class="event-settings-title">Event settings</p>
    <div class="section">
      <h5>Event name</h5>
      <div class="content">
        <DefaultTextInput
          v-model="eventData.name"
          @change="saveEventProperty('name', eventData.name)"
          :maxlength="100"
        />
      </div>
    </div>

    <div class="section registration">
      <h5>Registration</h5>
      <div class="content">
        <h6>
          Status
          <DefaultToggle
            v-model="eventData.isRegistrationOpen"
            @input="toggleRegistration()"
          />
        </h6>
        <p>
          When enabled players can register for this event. Choose to enable now
          or set a date when registration opens and Tjing will take care of it
          all.
        </p>
      </div>
      <div class="content vertical">
        <h6>Opening date</h6>

        <p>
          Set a date so users know when registration opens. If auto opening is
          enabled registration will open based on this date.
        </p>

        <TjingDateTimePicker
          class="datepicker"
          :canBeCleared="true"
          :readOnly="false"
          :options="registrationOpenTimes"
          :dateTime="eventData.registrationOpenAt"
          @update="updateRegistrationOpenTime($event)"
        />
      </div>

      <div class="content registration-stages vertical">
        <h6 @click="sectionControls.stages = !sectionControls.stages">
          Stages and criteria
          <span
            ><Chevron2 :direction="sectionControls.stages ? 'up' : 'down'"
          /></span>
        </h6>
        <p class="subtitle">Add stages and set criteria on a division level.</p>
        <slide-up-down
          class="w-100"
          :active="sectionControls.stages"
          :duration="300"
        >
          <div
            v-if="sectionControls.stages"
            :style="{
              minHeight: eventData.tour.divisions.length * 90 + 'px',
            }"
          >
            <div
              class="stage-division"
              :key="division.type"
              v-for="division in eventData.tour.divisions"
            >
              <p class="division-title">
                <b>{{ division.name }} </b>&nbsp;({{ division.type }})
              </p>
              <EventRegistrationStage
                class="mt-3"
                v-for="(stage, stageIndex) in stagesInDivision(division.id)"
                :stage="stage"
                :stageNumber="stageIndex + 1"
                :division="division"
                :key="stage.id"
                @reload="loadData()"
              />
              <EventRegistrationStage
                class="mt-3"
                :stageNumber="stagesInDivision(division.id).length + 1"
                :division="division"
                @reload="loadData()"
              />
            </div>
          </div>
        </slide-up-down>
      </div>
      <div class="content vertical">
        <h6>
          Auto open and auto activate
          <DefaultToggle
            v-model="eventData.autoOpenRegistration"
            :disabled="eventData.registrationOpenAt == null ? true : false"
            @input="
              saveEventProperty(
                'autoOpenRegistration',
                eventData.autoOpenRegistration
              )
            "
          />
        </h6>
        <p>
          When enabled Tjing will open registration for you based on the
          registration opening date you’ve set. If you have added stages those
          will also become automatically active based on the stage dates you
          have set.
        </p>
      </div>
    </div>
    <div class="section">
      <h5>Waiting list type</h5>
      <div class="content vertical">
        <DefaultRadioButton
          class="mb-4"
          groupname="waitinglist-type"
          :value="eventData.waitinglistType.type"
          @change="saveEventProperty('waitinglistType', 'DIVISION')"
          :options="{
            title: 'Division',
            value: 'DIVISION',
            description: 'Each division has a separate waiting list.',
          }"
        />
        <DefaultRadioButton
          groupname="waitinglist-type"
          :value="eventData.waitinglistType.type"
          @change="saveEventProperty('waitinglistType', 'COMBINED')"
          :options="{
            title: 'Combined',
            value: 'COMBINED',
            description: 'All players are placed into the same waiting list.',
          }"
        />
      </div>
    </div>
    <div class="section multiple">
      <div class="sub-section">
        <h5>
          Max players
          <TjingClickToolTip v-if="maxPlayersWarning" :iconType="'warning'">
            <div class="how-to">
              <p>
                Max number currently exceeds the number of available holes
                or/and group size. Only teetime start will be available on
                certain rounds.
              </p>
            </div>
          </TjingClickToolTip>
        </h5>

        <div class="content">
          <ValueSelector
            :values="maxSpotsOptions().values"
            :colors="maxSpotsOptions().colors"
            :title="''"
            @updatedValue="saveEventProperty('maxSpots', $event.value)"
          />
        </div>
      </div>
      <div class="sub-section">
        <h5>
          Group size
          <TjingClickToolTip>
            <div class="how-to">
              <p>
                Affects the number of players who can register. Depending on the
                current number of players you may not be able to choose smaller
                groups.
              </p>
            </div>
          </TjingClickToolTip>
        </h5>
        <div class="content vertical">
          <div class="selection-boxes">
            <ul>
              <li
                class="count-box"
                v-for="n in 4"
                :key="'box' + n"
                :class="{ active: n + 1 == eventData.groupSize }"
                @click="saveEventProperty('groupSize', n + 1)"
              >
                {{ n + 1 }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sub-section">
        <h5>Wildcards</h5>
        <div class="content">
          <ValueSelector
            :values="wildcardOptions.values"
            :colors="wildcardOptions.colors"
            :title="''"
            @updatedValue="saveEventProperty('maxWildcards', $event.value)"
            class="mt-2 mt-xl-0"
          />
        </div>
      </div>
    </div>

    <div class="section" v-if="managedTour">
      <h5>Stripe account</h5>
      <div class="content vertical">
        <select
          v-model="eventData.connectedAccount"
          class="large"
          @change="
            saveEventProperty(
              'connectedAccountId',
              eventData.connectedAccount.id
            )
          "
        >
          <option
            v-for="account in stripeAccount.availableAccounts"
            :key="account.id"
            :value="account"
          >
            {{ account.name }}
          </option>
        </select>
        <p class="">
          To change the Stripe account for this event, go to your
          <router-link to="/profile" class="mr-1">profile</router-link>page to
          set up Stripe and link to Tjing.
        </p>
      </div>
    </div>
    <div class="section">
      <h5>
        Check In
        <DefaultToggle v-model="checkInEnabled" @input="toggleCheckIn()" />
      </h5>

      <div class="content vertical">
        <p>
          Enable if you would like players to check in before an event to help
          you organise who may not be playing. Players that have checked in will
          have a checkmark displayed by their name under the players section in
          the Manager.
        </p>

        <slide-up-down
          tag="div"
          class="checkin-enabled"
          :active="checkInEnabled"
          :duration="500"
        >
          <div class="start">
            <p>
              <b>Opens:</b>
            </p>

            <TjingDateTimePicker
              class="datepicker"
              :canBeCleared="false"
              :readOnly="false"
              :options="checkInStartsOptions"
              :dateTime="eventData.checkInStartsAt"
              @update="updateCheckinStart($event)"
            />
          </div>
          <div class="end">
            <p>
              <b>Closes:</b>
            </p>

            <TjingDateTimePicker
              class="datepicker"
              :canBeCleared="false"
              :readOnly="false"
              :options="checkInEndsOptions"
              :dateTime="eventData.checkInEndsAt"
              @update="updateCheckinEnd($event)"
            />
          </div>
        </slide-up-down>
      </div>
    </div>
    <div class="section">
      <h5>
        The Verifier™
        <DefaultToggle
          v-model="eventData.isVerifyMode"
          @input="saveEventProperty('isVerifyMode', eventData.isVerifyMode)"
        />
      </h5>
      <div class="content">
        <p class="">
          The Verifier effectively reduces the chance of incorrect scores.
          <a href="https://support.tjing.se/verifier" target="_blank"
            >Learn more</a
          >
        </p>
      </div>
    </div>
    <div class="section">
      <h5>
        The Signature™
        <DefaultToggle
          v-model="eventData.isResultSigningEnabled"
          @input="
            saveEventProperty(
              'isResultSigningEnabled',
              eventData.isResultSigningEnabled
            )
          "
        />
      </h5>
      <div class="content">
        <p class="">
          The Digital Signature is a four digit PIN that players use to
          digitally sign their results.
          <a href="https://www.youtube.com/watch?v=XSkxigU_AIY" target="_blank"
            >Learn more</a
          >
        </p>
      </div>
    </div>
    <div class="section">
      <h5>
        PDGA tiers
        <DefaultToggle
          v-model="sectionControls.pdgaTiers"
          @input="togglePDGASanctioned()"
        />
      </h5>
      <div class="content radios">
        <p class="w-100">
          If your event is PDGA sanctioned then choose the appropriate tier so
          that players can find it more easily when searching.
        </p>
        <slide-up-down
          class="pdga-options"
          :active="sectionControls.pdgaTiers"
          :duration="300"
        >
          <DefaultRadioButton
            v-for="type in pdgaEventTypes"
            :key="type.type"
            groupname="pdga-type"
            :value="eventData.pdgaType"
            :options="{ title: type.name, value: type }"
            @change="saveEventProperty('pdgaType', type.type)"
          />
        </slide-up-down>
      </div>
    </div>

    <div class="section">
      <h5>
        PDGA Covid-19 Waiver
        <DefaultToggle
          v-model="firebaseData.pdgaWaiver"
          @input="updateFirebaseData($event)"
        />
      </h5>
      <div class="content">
        <p class="">
          A link to the COVID-19 waiver that players must agree to by clicking a
          checkbox in the checkout upon registering.
        </p>
      </div>
    </div>
    <div class="section" :class="{ collapsed: !customAgreementsExpanded }">
      <h5
        class="mb-0"
        @click="customAgreementsExpanded = !customAgreementsExpanded"
      >
        Custom agreements
        <span
          ><Chevron2 :direction="customAgreementsExpanded ? 'up' : 'down'"
        /></span>
      </h5>
      <div class="content vertical">
        <slide-up-down
          tag="div"
          class="w-100"
          :active="customAgreementsExpanded"
          :duration="300"
        >
          <p class="mt-3">
            Add a link to your own terms and conditions that players must agree
            to by clicking a checkbox in the checkout upon registering.
            <br />
          </p>
          <DefaultTextInput
            class="code-of-conduct"
            v-model="firebaseData.customAgreementsName"
            placeholder="Add name"
            :fat="true"
            title="Name of your terms"
            :maxlength="50"
          />
          <p
            class="agreement-example"
            v-if="firebaseData.customAgreementsName.length > 0"
          >
            In the checkout players will see: I have read and agree to the
            <span>{{ firebaseData.customAgreementsName }}</span>
          </p>
          <DefaultTextInput
            class="code-of-conduct"
            v-model="firebaseData.customAgreementsURL"
            placeholder="Add link"
            title="Link to your terms"
            :fat="true"
            :maxlength="100"
            @change="validateURL($event)"
          />
          <p class="mt-3 custom-terms-error" v-if="customAgreementInvalidURL">
            <span>Error:</span> Please ensure you include <b>http://</b> or
            <b>https://</b> at the beginning of your link.
          </p>
          <div class="action-buttons">
            <StandardBorderedButton
              v-if="firebaseData.customAgreements"
              class="save-custom-agreements"
              title="Remove"
              :fluid="false"
              :desktopFluidity="true"
              @click="disableCustomAgreements()"
            />
            <StandardSolidButton
              class="save-custom-agreements"
              title="Save"
              :fluid="false"
              :desktopFluidity="true"
              :disabled="!customAgreementsAreValid()"
              @click="saveCustomAgreements()"
            />
          </div>
        </slide-up-down>
      </div>
    </div>
    <div class="section">
      <h5>
        Round duration
        <DefaultToggle
          v-model="autoClosePool"
          @input="
            saveEventProperty(
              'autoCloseInactivePools',
              eventData.autoCloseInactivePools
            )
          "
        />
      </h5>
      <div class="content">
        <p class="">
          By default, rounds that have been live for 24 hours will be
          automatically closed. Switching this on will keep the round open
          indefinitely. We recommend leaving this switched off unless you have a
          specific case.
        </p>
      </div>
    </div>
    <div class="section">
      <h5>Delete event</h5>
      <div class="content vertical">
        <p>
          You can only delete an event if there haven’t been any player
          registrations.
          <span class="delete-event" @click="$refs.deletemodal.open()"
            >Delete event</span
          >
        </p>
      </div>
    </div>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="deletemodal"
      hide-close-button
    >
      <h3>Delete event</h3>
      <p>Are you sure you want to delete this event? It cannot be undone.</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.deletemodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Delete"
          :fluid="false"
          @click="deleteEvent()"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import validator from "validator";
import ValueSelector from "@/components/ValueSelector";
import EventRegistrationStage from "@/components/EventRegistrationStage";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import moment from "moment";
import db from "@/functions/firebaseInit";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import SlideUpDown from "vue-slide-up-down";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Chevron2 from "@/assets/icons/Chevron2";
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";

export default {
  name: "EventSettings",
  components: {
    ValueSelector,
    DefaultTextInput,
    DefaultToggle,
    DefaultRadioButton,
    TjingDateTimePicker,
    SlideUpDown,
    StandardSolidButton,
    StandardBorderedButton,
    EventRegistrationStage,
    Chevron2,
    TjingClickToolTip,
  },
  data() {
    return {
      sectionControls: {
        openingDate: false,
        stages: false,
        pdgaTiers: false,
      },
      eventData: {},
      pdgaEventTypes: [],
      customAgreementsExpanded: false,
      customAgreementInvalidURL: false,
      firebaseData: {
        pdgaWaiver: false,
        customAgreements: false,
        customAgreementsURL: "",
        customAgreementsName: "",
      },
      loading: true,
      showStatusMessage: false,
      statusMessageValue: true,
      connectedAccounts: [],
      checkInEnabled: false,
    };
  },
  watch: {
    $route: "loadData",
  },
  computed: {
    autoClosePool: {
      get: function () {
        return !this.eventData.autoCloseInactivePools;
      },
      set: function (newValue) {
        this.eventData.autoCloseInactivePools = !newValue;
      },
    },
    checkInStartsOptions() {
      return {
        minDateTime: null,
        maxDateTime: moment(this.eventData.checkInEndsAt),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    checkInEndsOptions() {
      return {
        minDateTime: moment(this.eventData.checkInStartsAt),
        maxDateTime: moment(this.eventData.startDate),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    eventStarted() {
      let eventActuallyStarted = false;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            eventActuallyStarted = true;
          }
        });
      });
      return eventActuallyStarted;
    },
    registrationOpenTimes() {
      return {
        minDateTime: null,
        maxDateTime: null,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },

    wildcardOptions() {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 20; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == this.eventData.maxWildcards ||
            (this.eventData.maxWildcards == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    checkinOptions() {
      let minutesBeforeCheckInStart = 180;
      let minutesBeforeCheckInClose = 60;

      if (
        this.eventData.checkInStartsAt != null ||
        this.eventData.checkInEndsAt != null
      ) {
        minutesBeforeCheckInStart = moment(this.eventData.startDate).diff(
          this.eventData.checkInStartsAt,
          "minutes"
        );

        minutesBeforeCheckInClose = moment(this.eventData.startDate).diff(
          this.eventData.checkInEndsAt,
          "minutes"
        );
      }

      let options = {
        open: {
          colors: {
            primary: this.$store.state.colors.primary.ocean.hex,
            secondary: "#FFF",
          },
          values: [],
        },
        close: {
          colors: {
            primary: this.$store.state.colors.secondary.dusk.hex,
            secondary: "#FFF",
          },
          values: [],
        },
      };

      for (let index = 1; index <= 3; index++) {
        options.close.values.push({
          value: 15 * index,
          displayValue: 15 * index,
          default: index * 15 == minutesBeforeCheckInClose ? true : false,
          text: "minutes before teeoff",
        });
      }

      for (let index = 1; index <= 48; index++) {
        options.close.values.push({
          value: index,
          displayValue: index * 60,
          default: index * 60 == minutesBeforeCheckInClose ? true : false,
          text: index == 1 ? "hour before teeoff" : "hours before teeoff",
        });

        options.open.values.push({
          value: index,
          displayValue: index * 60,
          default: index * 60 == minutesBeforeCheckInStart ? true : false,
          text: index == 1 ? "hour" : "hours before teeoff",
        });
      }

      return options;
    },
    stripeAccount() {
      let stripeAccount = {
        usedAccount: {},
        availableAccounts: [],
      };

      this.connectedAccounts.map((account) => {
        stripeAccount.availableAccounts.push(account);
      });

      if (this.eventData.tour.paymentType == "MANAGED") {
        if (this.eventData.connectedAccount == null) {
          if (
            stripeAccount.availableAccounts.filter(
              (account) => account.id == this.eventData.connectedAccount.id
            ).length == 0
          ) {
            stripeAccount.availableAccounts.push(
              this.eventData.connectedAccount
            );
          }
          stripeAccount.usedAccount = this.eventData.tour.connectedAccount;
        } else {
          if (
            stripeAccount.availableAccounts.filter(
              (account) => account.id == this.eventData.connectedAccount.id
            ).length == 0
          ) {
            stripeAccount.availableAccounts.push(
              this.eventData.connectedAccount
            );
          }

          stripeAccount.usedAccount = this.eventData.connectedAccount;
        }
      }

      return stripeAccount;
    },
    maxPlayersWarning() {
      let thresholdExceeded = false;

      for (var r = 0; r < this.eventData.rounds.length; r++) {
        let maxPlayersInRound = 0;

        for (var p = 0; p < this.eventData.rounds[r].pools.length; p++) {
          maxPlayersInRound +=
            this.eventData.rounds[r].pools[p].layoutVersion.holes.length *
            this.eventData.groupSize;
        }

        if (maxPlayersInRound < this.eventData.maxSpots) {
          thresholdExceeded = true;
        }
      }

      return thresholdExceeded;
    },
    eventNameValidation() {
      if (this.eventData.name.length < 3) return true;
      return false;
    },
    managedTour() {
      if (this.eventData.tour.paymentType == "MANAGED") return true;

      return false;
    },
    showMessage() {
      return this.$store.getters.showMessage;
    },
    availableGroupSizes() {
      var groupSizes = [];

      for (var g = 2; g <= 5; g++) {
        groupSizes.push(g);
      }
      return groupSizes;
    },
  },
  methods: {
    maxSpotsOptions() {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 999; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == this.eventData.maxSpots ||
            (this.eventData.maxSpots == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    togglePDGASanctioned() {
      if (this.sectionControls.pdgaTiers == false) {
        this.saveEventProperty("pdgaType", null);
      } else {
        this.saveEventProperty("pdgaType", "PDGA_A");
      }
    },
    stagesInDivision(divisionId) {
      let stagesInDivision = this.eventData.registrationStages.filter(
        (stage) => stage.division.id == divisionId
      );
      stagesInDivision = orderBy(stagesInDivision, "date", "asc");

      return stagesInDivision;
    },
    customAgreementsAreValid() {
      let valid = true;

      if (this.firebaseData.customAgreementsName.length < 3) {
        valid = false;
      }

      this.validateURL(this.firebaseData.customAgreementsURL);

      if (!this.validateURL(this.firebaseData.customAgreementsURL)) {
        valid = false;
      }

      return valid;
    },
    validateURL(url) {
      let valid = validator.isURL(url, {});
      if (url.toLowerCase().includes("http") == false) {
        valid = false;
      }

      if (this.firebaseData.customAgreementsURL.length != 0) {
        this.customAgreementInvalidURL = !valid;
      }
      return valid;
    },
    togglePublicGroups(pool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            UpdatePool(poolId:"${pool.id}", input:$UpdatePool){
              id
            }
          }
          `,
          variables: {
            UpdatePool: {
              areGroupsPublic: pool.areGroupsPublic,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
            style: "avocado",
          });
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
            style: "midnight",
          });
        });
    },
    async createGroupsOnly(round) {
      const startingHoles = [];

      round.pools.forEach((pool) => {
        startingHoles.push({
          poolId: pool.id,
          holeId: pool.layoutVersion.holes[0].id,
        });
      });

      console.log(round.pools[0]);

      try {
        if (round.pools[0].startMethod == "TEETIME") {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation CreateGroups($StartHoles: [PoolStartHoleInput!]){
                  CreateGroups(roundId:"${round.id}", creationMethod:GROUPS_ONLY, startHoles: $StartHoles){
                    id
                  }
                }`,
              variables: {
                StartHoles: startingHoles,
              },
            },
          });
        } else {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation{
                  CreateGroups(roundId:"${round.id}", creationMethod:GROUPS_ONLY){
                    id
                  }
                }
              `,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async makeGroupsPublic() {
      let publiciseQuery = "";

      this.eventData.rounds[0].pools.forEach((pool, index) => {
        let query = `public${index}: UpdatePool(poolId:"${pool.id}", input:$UpdatePool){id}`;
        publiciseQuery += query;
      });

      try {
        let publicGroups = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            ${publiciseQuery}
          }
          `,
            variables: {
              UpdatePool: {
                areGroupsPublic: true,
              },
            },
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async toggleRegistration() {
      var publishTour = "";
      let bool = this.eventData.isRegistrationOpen;

      if (this.eventData.tour.registrationMethod == "GROUPS") {
        let makeGroupsPublic = false;
        let createGroupsOnly = true;

        this.eventData.rounds[0].pools.forEach((pool) => {
          if (pool.groups.length !== 0) {
            createGroupsOnly = false;
          }
          if (pool.areGroupsPublic == false) {
            makeGroupsPublic = true;
          }
        });

        if (createGroupsOnly) {
          await this.createGroupsOnly(this.eventData.rounds[0]);
        }
        if (makeGroupsPublic) {
          await this.makeGroupsPublic();
        }
      }

      if (bool == true && this.eventData.tour.isPublished == false) {
        publishTour = `
            PublishTour(tourId:"${this.eventData.tour.id}"){
              id
            }`;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
              UpdateEvent(eventId:"${this.eventData.id}", input:$UpdateEvent){
                id
              }
              ${publishTour}
            }
          `,
          variables: {
            UpdateEvent: {
              isRegistrationOpen: bool,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.loadData();
          this.$store.dispatch(
            "loadManagedEventData",
            this.$route.params.eventId
          );
        })
        .catch(() => {});
    },
    loadFirebaseData() {
      db.collection("events")
        .doc(this.$router.currentRoute.params.eventId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.exists) {
            this.firebaseData.pdgaWaiver =
              querySnapshot.data().pdgaCovidWaiverEnabled;
            this.firebaseData.customAgreements =
              querySnapshot.data().customAgreements == undefined
                ? false
                : querySnapshot.data().customAgreements;
            this.firebaseData.customAgreementsURL =
              querySnapshot.data().customAgreementsURL == undefined
                ? ""
                : querySnapshot.data().customAgreementsURL;
            this.firebaseData.customAgreementsName =
              querySnapshot.data().customAgreementsName == undefined
                ? ""
                : querySnapshot.data().customAgreementsName;

            this.firebaseData.customAgreements == true
              ? (this.customAgreementsExpanded = true)
              : (this.customAgreementsExpanded = false);
          } else {
            this.firebaseData.pdgaWaiver = false;
            this.firebaseData.customAgreements = false;
            this.firebaseData.customAgreementsURL = "";
            this.firebaseData.customAgreementsName = "";
          }
        });
    },
    disableCustomAgreements() {
      this.firebaseData.customAgreements = false;
      this.firebaseData.customAgreementsName = "";
      this.firebaseData.customAgreementsURL = "";
      this.customAgreementInvalidURL = false;
      this.updateFirebaseData();
    },
    saveCustomAgreements() {
      this.firebaseData.customAgreements = true;
      this.updateFirebaseData();
    },
    async updateFirebaseData() {
      try {
        await db
          .collection("events")
          .doc(this.$router.currentRoute.params.eventId)
          .set({
            eventId: this.$router.currentRoute.params.eventId,
            pdgaCovidWaiverEnabled: this.firebaseData.pdgaWaiver,
            customAgreements: this.firebaseData.customAgreements,
            customAgreementsName: this.firebaseData.customAgreementsName,
            customAgreementsURL:
              this.firebaseData.customAgreements == false
                ? ""
                : this.firebaseData.customAgreementsURL,
          });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    updateCheckinStart(value) {
      this.saveEventProperty("checkInStartsAt", value);
    },
    updateCheckinEnd(value) {
      this.saveEventProperty("checkInEndsAt", value);
    },
    toggleCheckIn() {
      let checkInStart = moment(this.eventData.startDate).subtract(2, "hours");
      let checkInEnd = moment(this.eventData.startDate).subtract(15, "minutes");

      if (this.checkInEnabled == false) {
        checkInStart = null;
        checkInEnd = null;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
          }
          `,
          variables: {
            UpdateEvent: {
              checkInStartsAt: checkInStart,
              checkInEndsAt: checkInEnd,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.$store.dispatch(
            "loadManagedEventData",
            this.$route.params.eventId
          );
          this.loadData();
        })
        .catch(() => {
          this.showStatusMessage = true;
          this.statusMessageValue = false;

          var self = this;
          setTimeout(function () {
            self.showStatusMessage = false;
          }, 1200);
        });
    },
    makeMomentDate(value) {
      return moment(value);
    },
    async updateRegistrationOpenTime(value) {
      try {
        let eventUpdate = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
          }
          `,
            variables: {
              UpdateEvent: {
                autoOpenRegistration:
                  value == null ? false : this.eventData.autoOpenRegistration,
                registrationOpenAt: value,
              },
            },
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
        this.$store.dispatch(
          "loadManagedEventData",
          this.$route.params.eventId
        );
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    connectStripe() {
      location.href = process.env.VUE_APP_STRIPE_ACCOUNT_ADDITION_URL;
    },
    async deleteEvent() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            DeleteEvent(eventId:"${this.$router.currentRoute.params.eventId}")
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.errors) {
            this.$refs.deletemodal.close();
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Unable to delete event",
              type: "error",
            });
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Deleted",
              type: "success",
            });

            this.$refs.deletemodal.close();

            this.$store.dispatch("loadManagedEvents");
            this.$router.push({
              name: "manage",
              params: { tourId: this.eventData.tour.id },
            });
          }
        })
        .catch(() => {
          this.$refs.deletemodal.close();
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Unable to delete event",
            type: "error",
          });
          this.$router.push({
            name: "tour-overview",
            params: { tourId: this.eventData.tour.id },
          });
        });
    },
    saveEventProperty(property, value) {
      var mutation = "";
      if (
        this.eventData.isRegistrationOpen == true &&
        this.eventData.tour.isPublished == false
      ) {
        mutation = `
          PublishTour(tourId:"${this.eventData.tour.id}"){
            id
          }`;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
            ${mutation}
          }
          `,
          variables: {
            UpdateEvent: {
              [property]: value,
            },
          },
        },
      })
        .then((result) => {
          if (result.data.errors) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Error",
              type: "error",
            });
            this.loadData();
            this.$store.dispatch(
              "loadManagedEventData",
              this.$route.params.eventId
            );
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
            this.loadData();
            this.$store.dispatch(
              "loadManagedEventData",
              this.$route.params.eventId
            );
          }
        })
        .catch((err) => {
          console.log(err);

          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    loadData() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me{
              connectedAccounts{
                id
                name
                owner{
                  id
                  firstName
                  lastName
                }
              }
            }
            pdgaEventTypes{
                name
                type
            }
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              name
              maxSpots
              autoCloseInactivePools
              autoOpenRegistration
              checkInStartsAt
              checkInEndsAt
              isRegistrationOpen
              playersCount
              isVerifyMode
              isDeletable
              startDate
              isResultSigningEnabled
              maxWildcards
              registrationOpenAt
              paymentInfo
              registrationFeesInfo
              groupSize
              startDate
              registrationStages{
                id
                date
                active
                criterias{
                  id
                  type
                  value
                }
                division{
                  id
                  type
                  position
                  name
                }
              }
              waitinglistType{
                type
                name
              }
              pdgaType{
                name
                type
              }
              connectedAccount{
                id
                name
                owner{
                  firstName
                  lastName
                  id
                }
              }
              rounds{
                id
                pools{
                  id
                  areGroupsPublic
                  position
                  status
                  date
                  startMethod
                  groups{
                  id
                  }
                  layoutVersion{
                    holes{
                      id
                      number
                    }
                  }
                }
              }
              tour{
                id
                paymentType
                isPublished
                registrationMethod
                divisions{
                  id
                  type
                  name
                }
                connectedAccount{
                  id
                  name
                  owner{
                    firstName
                    lastName
                    id
                  }
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.connectedAccounts = result.data.data.me.connectedAccounts;
          this.eventData = result.data.data.event;

          if (
            this.eventData.checkInStartsAt != null ||
            this.eventData.checkInEndsAt != null
          ) {
            this.checkInEnabled = true;
          }

          if (this.eventData.connectedAccount == null) {
            this.eventData.connectedAccount =
              this.eventData.tour.connectedAccount;
          }

          this.pdgaEventTypes = result.data.data.pdgaEventTypes;
          this.loadFirebaseData();

          if (this.eventData.registrationStages.length > 0) {
            this.sectionControls.stages = true;
          }
          if (this.eventData.pdgaType != null) {
            this.sectionControls.pdgaTiers = true;
          }
          this.loading = false;
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.event-settings-title {
  font-size: 16px;
  margin-bottom: 18px;
}
a {
  color: $teal;

  transition: 0.3s;

  &:hover {
    color: $teal;
    text-decoration: underline;
  }
}
.event-settings {
  padding: 0 12px;

  .section {
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 1px $sleet;
    margin-bottom: 24px;
    border-radius: 4px;
    h5 {
      font-size: 16px;
      margin-bottom: 12px;
      @include Gilroy-Bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    p {
      margin: 0;

      &.warning {
        margin-top: 16px;
        color: $dusk;
      }
    }

    &.multiple {
      flex-direction: row;
      box-shadow: none;
      background: none;
      padding: 0;

      .sub-section {
        padding: 20px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0 3px $sleet;
        margin-bottom: 24px;

        h5 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .tooltip-container {
          margin-left: 8px;
          p {
            white-space: normal;
          }
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &:not(:first-of-type) {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $fog;
      }

      h6 {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
      }

      .slide-controller {
        display: flex;
        flex-direction: column;
        width: 100%;
        > div {
          min-height: 0px;
        }
      }

      .custom-terms-error {
        span {
          color: $dusk;
        }
        b {
          @include Gilroy-Bold;
          display: inline;
          width: auto !important;
        }
      }

      b {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
      }
      p {
        margin: 0;

        &.warning {
          margin-top: 10px;
          color: $dusk;
        }
      }
      .stage-division {
        width: 100%;
      }

      .division-title {
        font-size: 14px;
        margin: 0;
        width: 100%;
        margin-top: 24px;

        b {
          display: inline;
          @include Gilroy-Bold;
          width: auto;
          margin: 0;
        }
      }

      .datepicker {
        margin-top: 16px;
        width: 100%;
      }

      .agreement-example {
        margin-top: 12px;
        margin-bottom: 0;

        span {
          color: $ocean;
        }
      }

      .action-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        button {
          width: 47%;
        }
      }

      .code-of-conduct {
        margin-top: 24px;
      }

      .checkin-enabled {
        display: flex;
        flex-direction: column;
        width: 100%;

        > div {
          margin-top: 16px;
          p {
            margin-bottom: 12px;
          }
        }
      }

      .delete-event {
        color: $dusk;
        cursor: pointer;
      }

      &.radios {
        justify-content: flex-start;
        h5 {
          margin-bottom: 0;
          font-size: 16px;
          padding-top: 7px;
        }

        .default-radio-buttons {
          width: 50%;
          margin-top: 16px;
        }

        .pdga-options {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
        }
      }

      .default-toggle {
        margin-left: 30px;
      }

      &.vertical {
        flex-direction: column;
        align-items: flex-start;
      }

      .archive {
        color: $teal;
        cursor: pointer;
      }
      .delete {
        color: $dusk;
        cursor: pointer;
      }

      .left {
        width: 100%;
        border-bottom: 1px solid $sleet;
        padding-bottom: 30px;
      }
      .selection-boxes {
        display: flex;
        width: 100%;

        ul {
          list-style: none;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          margin: 0;
          padding: 0;

          li {
            border-radius: 6px;
            height: 40px;
            width: 40px;
            border: 1px solid $sleet;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $sleet;
            margin-right: 10px;
            margin-bottom: 15px;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
              border: 1px solid $midnight;
              transition: 0.3s;
            }

            &.active {
              border: 1px solid $twilight;
              background-color: $twilight;
              color: white;
              transition: 0.3s;
              @include Gilroy-Bold;

              &:hover {
                background-color: $twilightshade;
                transition: 0.3s;
              }
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-top: 30px;

        .default-toggle {
          margin-left: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .event-settings {
    .section {
      .content {
        .pdga-options {
          .default-radio-buttons {
            width: 20%;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .event-settings-title {
    font-size: 18px;
    margin-bottom: 32px;
  }
  .event-settings {
    padding: 0;
    .section {
      padding: 30px 30px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 3px $sleet;
      margin-bottom: 24px;

      &.collapsed {
        padding-bottom: 30px;
      }

      &.multiple {
        flex-direction: row;
        box-shadow: none;
        flex-wrap: nowrap;
        display: flex;
        background: white;
        border-radius: 4px;
        box-shadow: 0 0 3px $sleet;
        justify-content: space-between;

        .sub-section {
          width: 33.3%;
          padding: 30px 30px 40px 30px;
          background-color: white;
          border-radius: 0px;
          box-shadow: none;
          margin: 0px;
          justify-content: center;
          display: flex;
          flex-direction: column;

          &:not(:first-of-type) {
            border-left: 1px solid $fog;
          }
          h5 {
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;

            .tooltip-container {
              margin-left: 8px;
            }
          }
          .content {
            justify-content: center;
            margin: 0;

            .selection-boxes {
              justify-content: center;
              margin-top: 0;
              margin-bottom: 0;
              ul {
                width: auto;
                li {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      h5 {
        font-size: 20px;
        margin-bottom: 24px;

        span {
          cursor: pointer;
          display: flex;
          width: 21px;
          height: 12px;
          svg {
            width: 21px;
            height: 12px;
          }
        }
      }

      p {
        margin: 0;

        &.warning {
          margin-top: 16px;
          color: $dusk;
          padding-left: 20px;
        }
      }

      .content {
        margin: 0 0 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.registration-stages {
          h6 {
            cursor: pointer;
          }
        }

        h6 {
          font-size: 16px;
          span {
            display: flex;
            width: 21px;
            height: 12px;
            cursor: pointer;
            svg {
              width: 21px;
              height: 12px;
            }
          }
        }

        b {
          width: 100%;
        }

        p {
          font-size: 16px;
        }

        &:not(:first-of-type) {
          border-top: 1px solid $sleet;
          margin-top: 30px;
          padding-top: 30px;
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;

          button {
            width: auto;
            margin-left: 15px;
          }
        }

        .datepicker {
          max-width: calc(50% - 20px);
        }

        .stage-division {
          width: calc(50% - 20px);
        }

        .checkin-enabled {
          display: flex;
          flex-direction: column;
        }

        .left {
          width: 50%;
          border-right: 1px solid $sleet;
          border-bottom: 0px solid $sleet;
        }

        .selection-boxes {
          display: flex;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 9px;

          ul {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
              border-radius: 6px;
              height: 48px;
              width: 48px;
              border: 1px solid $blizzard;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $blizzard;
              margin-right: 15px;
              margin-bottom: 15px;
              transition: 0.3s;
              font-size: 16px;
              @include Gilroy-Bold;

              &.active {
                border: 1px solid $twilight;
                background-color: $twilight;
                color: white;
                transition: 0.3s;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: space-between;
          padding-left: 40px;
          width: 50%;
          flex-wrap: wrap;
          align-items: flex-start;
          padding-top: 0;
        }

        &.vertical {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      select {
        max-width: calc(50% - 20px);
      }
    }
  }
}
</style>
