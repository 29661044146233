<template>
  <slide-up-down tag="div" class="stage" :active="loaded" :duration="300">
    <div
      class="stage"
      v-if="loaded"
      :class="{
        error: showStageError,
        expanded: collapsed == false || (criterias.length > 0 && !editMode),
      }"
    >
      <div class="stage-title" v-if="editMode || criterias.length > 0">
        <p>Stage {{ stageNumber }}</p>
        <StandardSmallSolidButton
          v-if="editMode"
          :title="collapsed ? 'Edit' : 'Done'"
          :fluid="true"
          @click="collapsed = !collapsed"
        />
      </div>
      <slide-up-down tag="div" :active="editMode && collapsed">
        <div class="collapsed-stage" v-if="editMode">
          <p class="date">{{ formattedStageDate }}</p>
          <div class="criterias">
            <div
              class="criteria"
              v-for="(criteria, criteriaIndex) in criterias"
              :key="criteria.id + 'c' + criteriaIndex"
            >
              <template v-if="criteria.type != null">
                <p v-if="criterias.length > 1">
                  Criteria {{ criteriaIndex + 1 }}:&nbsp;
                </p>
                <p v-if="criteria.type.value == 'MAX_PDGA_RATING'">
                  Max {{ criteria.value }} rated players
                </p>
                <p v-if="criteria.type.value == 'MIN_PDGA_RATING'">
                  Min {{ criteria.value }}+ rated players
                </p>
                <p v-if="criteria.type.value == 'OPEN_FOR_ALL'">
                  Anyone can register
                </p>
                <p v-if="criteria.type.value == 'PDGA_NUMBER'">
                  Only PDGA members
                </p>
              </template>
            </div>
            <template v-if="showStageError">
              <p class="stage-error">
                <span>Error: </span> This stage doesn’t have any criteria.
                Players will not be able to register for this division unless
                you add criteria or delete this stage.
              </p>
            </template>
          </div>
          <div class="status" v-if="!showStageError">
            <p>Status:</p>
            <DefaultToggle v-model="active" @input="updateStage()" />
          </div>
        </div>
      </slide-up-down>
      <slide-up-down
        tag="div"
        :active="collapsed == false || editMode == false"
      >
        <div
          class="add-stage"
          @click="addBlankCriteria()"
          v-if="criterias.length == 0"
        >
          <p>Add stage</p>
          <Add />
        </div>

        <slide-up-down tag="div" :active="criterias.length > 0" :duration="300">
          <div class="expanded-stage" v-if="criterias.length > 0">
            <div class="stage-date">
              <p>Effective from</p>
              <TjingDateTimePicker
                class="datepicker"
                :canBeCleared="false"
                :readOnly="false"
                :options="stageDateOptions"
                :dateTime="date"
                @update="updateStageDate($event)"
              />
            </div>
            <div class="criterias">
              <p>Criteria</p>
              <div
                class="criteria"
                :class="{ expanded: criteria.type != null }"
                v-for="(criteria, criteriaIndex) in criterias"
                :key="'criteria' + criteriaIndex"
              >
                <slide-up-down
                  tag="div"
                  :active="criteria.type == null"
                  :duration="300"
                >
                  <select
                    class="criteria-selector"
                    :class="{ disabled: criteria.type == null }"
                    v-model="criteria.type"
                    @change="criteriaAddingValidation(criteria)"
                  >
                    <option :value="null">
                      {{
                        criterias.length > 1
                          ? "Choose criteria (optional)"
                          : "Choose criteria"
                      }}
                    </option>
                    <option
                      v-for="type in types"
                      :disabled="criteriaAlreadyUsed(type)"
                      :value="type"
                      :key="type.value"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                </slide-up-down>
                <slide-up-down
                  tag="div"
                  :active="criteria.type != null"
                  :duration="300"
                >
                  <template v-if="criteria.type != null">
                    <div class="title">
                      <p>{{ criteria.type.name }}</p>
                      <span
                        @click="removeCriteria(criteria, criteriaIndex)"
                        v-if="criteria.id"
                        ><Remove
                      /></span>
                      <span
                        @click="removeUnsavedCriteria(criteria, criteriaIndex)"
                        v-else
                        ><Remove
                      /></span>
                    </div>
                    <div
                      class="values"
                      v-if="criteria.type.value == 'MAX_PDGA_RATING'"
                    >
                      <input
                        type="text"
                        placeholder="Rating"
                        maxlength="4"
                        v-model="criteria.value"
                        @blur="addOrUpdateCriteria(criteria)"
                        @keypress="onlyNumber"
                        @keydown.enter="$event.target.blur()"
                      />
                      <p
                        class="small"
                        v-if="criteria.value != null && criteria.value != ''"
                      >
                        Only players rated
                        {{
                          criteria.value == null ? "[rating]" : criteria.value
                        }}
                        and lower will be able to register.
                      </p>
                    </div>
                    <div
                      class="values"
                      v-if="criteria.type.value == 'MIN_PDGA_RATING'"
                    >
                      <input
                        type="text"
                        placeholder="Rating"
                        maxlength="4"
                        v-model="criteria.value"
                        @blur="addOrUpdateCriteria(criteria)"
                        @keypress="onlyNumber"
                        @keydown.enter="$event.target.blur()"
                      />
                      <p
                        class="small"
                        v-if="criteria.value != null && criteria.value != ''"
                      >
                        Only players rated
                        {{
                          criteria.value == null ? "[rating]" : criteria.value
                        }}
                        and higher will be able to register.
                      </p>
                    </div>
                  </template>
                </slide-up-down>
              </div>
            </div>

            <!--<div
              class="add-criteria"
              v-if="
                (allCriteriasAreValid || criterias.length == 0) &&
                criterias.length < 4
              "
            >
              <div class="additional-criteria" @click="addBlankCriteria()">
                <span>Add critiera</span>
                <Add />
              </div>
            </div>-->

            <div class="control-buttons" v-if="!editMode">
              <StandardBorderedButton
                title="Cancel"
                :fluid="false"
                @click="resetAddStage()"
              />
              <StandardSolidButton
                title="Save"
                :fluid="false"
                :disabled="date == null || !allCriteriasAreValid"
                @click="createRegistrationStage()"
              />
            </div>

            <div class="delete-stage" @click="deleteStage()" v-if="editMode">
              <p>Delete this stage</p>
            </div>
          </div>
        </slide-up-down>
      </slide-up-down>
    </div>
  </slide-up-down>
</template>


<script>
import moment from "moment";
import Add from "@/assets/icons/Add";
import Remove from "@/assets/icons/Remove";
import SlideUpDown from "vue-slide-up-down";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";

export default {
  props: ["stageNumber", "division", "stage"],
  name: "EventRegistrationStage",
  components: {
    Add,
    Remove,
    SlideUpDown,
    StandardSolidButton,
    StandardBorderedButton,
    TjingDateTimePicker,
    StandardSmallSolidButton,
    DefaultToggle,
  },
  data() {
    return {
      loaded: false,
      collapsed: true,
      active: false,
      criterias: [],
      date: null,
      types: [
        { value: "PDGA_NUMBER", name: "Active PDGA member" },
        { value: "MAX_PDGA_RATING", name: "Maximum PDGA rating" },
        { value: "MIN_PDGA_RATING", name: "Minimum PDGA rating" },
        { value: "OPEN_FOR_ALL", name: "Open for all" },
      ],
    };
  },
  computed: {
    showStageError() {
      let showError = false;

      if (this.stage) {
        if (this.collapsed && this.criterias.length == 0) {
          showError = true;
        }
        if (this.collapsed && this.allCriteriasAreValid == false) {
          showError = true;
        }
      }

      return showError;
    },
    allCriteriasAreValid() {
      let valid = true;

      this.criterias.forEach((criteria) => {
        if (criteria.type != null) {
          if (
            criteria.type.value == "MAX_PDGA_RATING" ||
            criteria.type.value == "MIN_PDGA_RATING"
          ) {
            if (criteria.value == "" || criteria.value == null) {
              valid = false;
            }
          }
        }
      });

      if (this.criterias.length == 1 && this.criterias[0].type == null) {
        valid = false;
      }

      return valid;
    },
    formattedStageDate() {
      return moment(this.date).format("ddd, D MMM YYYY [at] HH:mm");
    },
    editMode() {
      return this.stage ? true : false;
    },
    stageDateOptions() {
      return {
        minDateTime: moment().subtract(1, "months"),
        maxDateTime: null,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
  },
  watch: {
    collapsed: function (newVal) {
      if (newVal == false) {
        if (this.criterias.length == 0 && this.editMode) {
          this.addBlankCriteria();
        } else if (
          this.criterias.length > 0 &&
          this.criterias[this.criterias.length - 1].type != null
        ) {
          this.addBlankCriteria();
        }
      } else {
        if (
          this.criterias.length > 0 &&
          this.criterias[this.criterias.length - 1].type == null
        ) {
          this.criterias.pop();
        }
      }
    },
    stage: function () {
      this.initialize();
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    removeUnsavedCriteria(criteria, criteriaIndex) {
      if (this.criterias.length > 1) {
        this.criterias.splice(criteriaIndex, 1);
      } else {
        this.criterias[criteriaIndex].type = null;
        this.criterias[criteriaIndex].value = null;
      }
    },
    criteriaAlreadyUsed(type) {
      let alreadyUsed = false;

      this.criterias.forEach((criteria) => {
        if (criteria.type != null) {
          if (criteria.type.value == type.value) {
            alreadyUsed = true;
          }

          if (criteria.type.value == "OPEN_FOR_ALL") {
            alreadyUsed = true;
          }
          if (criteria.type.value == "PDGA_NUMBER") {
            alreadyUsed = true;
          }
          if (
            (criteria.type.value == "MAX_PDGA_RATING" &&
              type.value != "MIN_PDGA_RATING") ||
            (criteria.type.value == "MIN_PDGA_RATING" &&
              type.value != "MAX_PDGA_RATING")
          ) {
            alreadyUsed = true;
          }
        }
      });

      return alreadyUsed;
    },
    updateStageDate(date) {
      this.date = date;

      if (this.stage) {
        this.updateStage();
      }
    },
    async updateStage(criteria) {
      let updateStage = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateRegistrationStage($UpdateRegistrationStage: UpdateRegistrationStageInput!){
            UpdateRegistrationStage(registrationStageId:"${this.stage.id}", input:$UpdateRegistrationStage){
              id
            }
          }
          `,
          variables: {
            UpdateRegistrationStage: {
              date: moment(this.date).toISOString(),
              active: this.active,
            },
          },
        },
      });

      this.$store.dispatch("showMessage", {
        show: true,
        message: "Stage updated",
        type: "success",
        style: "avocado",
      });
    },
    reset() {
      this.criterias = [];
      this.date = null;
      this.collapsed = true;
      this.active = false;
    },
    initialize() {
      if (this.editMode) {
        this.date = this.stage.date;
        this.active = this.stage.active;
        this.criterias = [];

        this.stage.criterias.forEach((criteria) => {
          let criteriaType = this.types.filter((type) => {
            return type.value == criteria.type;
          })[0];

          this.criterias.push({
            id: criteria.id,
            type: criteriaType,
            value: criteria.value,
          });
        });

        if (
          this.stage.criterias.length == 0 ||
          (this.stage.criterias.length == 1 &&
            this.stage.criterias[0].type == "MIN_PDGA_RATING") ||
          this.stage.criterias[0].type == "MAX_PDGA_RATING"
        ) {
          this.addBlankCriteria();
        }
      }

      setTimeout(() => {
        this.loaded = true;
      }, 100);
    },
    async updateCriteria(criteria) {
      if (this.editMode) {
        let updateCriteria = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdateRegistrationCriteria($UpdateRegistrationCriteria: UpdateRegistrationCriteriaInput!){
              UpdateRegistrationCriteria(registrationCriteriaId:"${criteria.id}",input:$UpdateRegistrationCriteria){
                id
              }
            }
          `,
            variables: {
              UpdateRegistrationCriteria: {
                type: criteria.type.value,
                value: criteria.value,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Criteria updated",
          type: "success",
          style: "avocado",
        });
      }
    },
    addOrUpdateCriteria(criteria) {
      setTimeout(() => {
        if (criteria.id.length == 0) {
          //this.addCriteria(criteria);
          this.criteriaAddingValidation(criteria);
        } else {
          this.updateCriteria(criteria);
        }
      }, 100);
    },
    async removeCriteria(criteria, criteriaIndex) {
      let removeCriteria = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation{
              DeleteRegistrationCriteria(registrationCriteriaId:"${criteria.id}")
            }
          `,
        },
      });

      this.$emit("reload");

      this.$store.dispatch("showMessage", {
        show: true,
        message: "Criteria removed",
        type: "success",
        style: "avocado",
      });
    },
    criteriaAddingValidation(criteria) {
      let valid = false;
      if (
        criteria.type.value == "OPEN_FOR_ALL" ||
        criteria.type.value == "PDGA_NUMBER"
      ) {
        valid = true;
      }

      if (
        criteria.type.value == "MAX_PDGA_RATING" &&
        criteria.value != null &&
        criteria.value != ""
      ) {
        valid = true;
      }
      if (
        criteria.type.value == "MIN_PDGA_RATING" &&
        criteria.value != null &&
        criteria.value != ""
      ) {
        valid = true;
      }

      if (valid) {
        this.addCriteria(criteria);

        if (!this.stage) {
          if (
            (this.criterias[0].type.value == "MIN_PDGA_RATING" ||
              this.criterias[0].type.value == "MAX_PDGA_RATING") &&
            this.criterias.length < 2
          ) {
            this.addBlankCriteria();
          }
        }
      }
    },
    async addCriteria(criteria) {
      if (this.stage && this.stage.id) {
        let addCriteria = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation CreateRegistrationCriteria($CreateRegistrationCriteria: CreateRegistrationCriteriaInput!) {
              CreateRegistrationCriteria(registrationStageId: "${this.stage.id}", input: $CreateRegistrationCriteria) {
                id
              }
            }
          `,
            variables: {
              CreateRegistrationCriteria: {
                type: criteria.type.value,
                value: criteria.value,
              },
            },
          },
        });
        if (
          criteria.type.value == "MAX_PDGA_RATING" ||
          criteria.type.value == "MIN_PDGA_RATING"
        ) {
          this.addBlankCriteria();
        }
        this.$emit("reload");

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Criteria added",
          type: "success",
          style: "avocado",
        });
      }
    },
    resetAddStage() {
      (this.date = null), (this.criterias = []);
    },
    addBlankCriteria() {
      this.criterias.push({
        id: "",
        type: null,
        value: null,
      });
    },
    async deleteStage() {
      try {
        let deleteStage = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            DeleteRegistrationStage(registrationStageId:"${this.stage.id}")
          }
          `,
          },
        });

        this.loaded = false;
        setTimeout(() => {
          this.$emit("reload");
        }, 300);

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Stage deleted",
          type: "success",
          style: "avocado",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async createRegistrationStage() {
      if (this.date !== null && this.allCriteriasAreValid) {
        let formattedCriterias = [];

        this.criterias.forEach((criteria) => {
          if (criteria.type != null) {
            formattedCriterias.push({
              type: criteria.type.value,
              value: criteria.value,
            });
          }
        });

        try {
          let stage = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
          mutation CreateRegistrationStage($CreateRegistrationStage: CreateRegistrationStageInput!){
            CreateRegistrationStage(eventId:"${this.$route.params.eventId}",divisionId:"${this.division.id}",input:$CreateRegistrationStage){
              id
              date
              
            }
          }
          `,
              variables: {
                CreateRegistrationStage: {
                  date: moment(this.date).toISOString(),
                  active: false,
                  criterias: formattedCriterias,
                },
              },
            },
          });
          this.reset();

          setTimeout(() => {
            this.$emit("reload");
          }, 300);

          this.$store.dispatch("showMessage", {
            show: true,
            message: "Stage added",
            type: "success",
          });
        } catch (e) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
  },
  beforeMount() {
    this.initialize();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.stage-title {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  p {
    @include Gilroy-Bold;
    margin: 0;
  }
}
.collapsed-stage {
  padding: 0 15px 15px 15px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      @include Gilroy-Bold;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
  }
  .status {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid $fog;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .criterias {
    .criteria {
      margin-top: 8px;
      display: flex;
      p {
        margin-right: 6px;
      }
    }

    .stage-error {
      margin-top: 12px;
      span {
        color: $dusk;
      }
    }
  }
}
.expanded-stage {
  padding: 0px 15px 25px 15px;

  .additional-criteria {
    height: 48px;
    border: 1px solid $blizzard;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    border-radius: 6px;
    padding: 0 15px;
    span {
      color: $blizzard;
    }
  }

  .delete-stage {
    cursor: pointer;
    width: 100%;
    p {
      margin-top: 24px;
      margin-bottom: 0;
      text-align: center;
      color: $dusk;
      font-size: 12px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      @include Gilroy-Bold;
      margin-bottom: 0px;
      font-size: 14px;
    }

    span {
      cursor: pointer;
    }
  }

  .add-criteria {
    color: $blizzard;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 24px;
    p {
      font-size: 12px;
    }
  }

  .criterias {
    margin-top: 16px;

    p {
      margin: 0;
    }
  }

  .criteria {
    margin-top: 12px;
    box-shadow: 0 0 0 1px $blizzard;
    border-radius: 6px;

    transition: all ease 0.3s;
    &.expanded {
      padding: 11px 0 12px 0;
      .title {
        padding: 0 15px;
      }
    }
    &:hover {
      box-shadow: 0 0 0 1px $midnight;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        @include Gilroy-Bold;
        font-size: 16px;
      }
    }

    input {
      width: 70px;
      height: 48px;
      border: 1px solid $blizzard;
      border-radius: 6px;
      transition: all ease 0.3s;
      @include Gilroy-Bold;
      text-align: center;
      color: $midnight;
      font-size: 16px;
      margin-bottom: 12px;

      &::placeholder {
        color: $blizzard;
        @include Gilroy-Medium;
      }

      &:hover {
        border: 1px solid $midnight;
      }
      &:focus {
        outline: 0;
        border: 1px solid $ocean;
      }
    }

    .values {
      color: $strom;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 16px;
      padding: 0 15px;
      p {
        width: calc(100% - 70px);
        margin-bottom: 12px;
        padding-left: 15px;
        font-size: 12px;
      }
    }
  }
  .criteria-selector {
    margin-bottom: 0;
    border: none;
    border-radius: 6px;
    font-size: 16px;

    &.disabled {
      color: $blizzard;
      @include Gilroy-Medium;

      option {
        color: $midnight;
        @include Gilroy-Bold;

        &:disabled {
          color: $fog;
        }
      }
    }
  }
  .control-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    button {
      width: 47%;
    }
  }
}

.stage {
  box-shadow: 0 0 0 1px $blizzard;
  border-radius: 6px;
  transition: all ease 0.3s;

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }
  &.expanded {
    box-shadow: 0 0 0 2px $ocean;
  }
}

.add-stage {
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  p {
    font-size: 16px;
    color: $blizzard;
    margin: 0;
    @include Gilroy-Medium;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
